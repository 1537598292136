import React from 'react'
import WebDataForm from '../../../forms/web-information/web-data'
import { getAuthToken, getToken } from '../../../../redux/selectors'
import { useSelector } from 'react-redux'
import { useToast } from '@chakra-ui/core'
import { deconstructPermission } from '../../../../helpers/permissions-functions'
import { toastCustomizedError, toastCustomizedSuccess } from '../../../common/toast-default-config'
import { useTranslation } from 'react-i18next'
import { UpdateCompanyDetail } from '../../../../api/web-information'
import { COMPANY_DETAIL_CREATE } from '../../../../helpers/permissions'

export default function CreateWebData({ onFinish, getCompanyDetail, onClose, ...restProps }) {
  const { t } = useTranslation(['common', 'forms'])
  const token = useSelector(getToken)
  const authToken = useSelector(getAuthToken)
  const toast = useToast()

  const handleSubmit = async formData => {
    let sectionId = restProps.idSection
    try {
      const sesion = {
        token,
        ...deconstructPermission(COMPANY_DETAIL_CREATE),
      }

      const data = { sesion, ...formData }
      const response = await UpdateCompanyDetail(sectionId, data, authToken)
      onClose()
      toast(
        response.status
          ? toastCustomizedSuccess(t('forms:actionCompleted'))
          : toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')),
      )
    } catch (error) {
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
    onFinish()
  }

  return (
    <div>
      <WebDataForm onSubmit={handleSubmit} buttonLabel={t('common:create')} idSection={restProps.idSection} />
    </div>
  )
}
