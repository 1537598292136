import { Box, Button, Flex, Grid, List, Heading } from '@chakra-ui/core'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { GiHamburgerMenu } from 'react-icons/gi'
import { useDispatch, useSelector } from 'react-redux'
import { MARCA_BLANCA_CRUD_ADMIN } from '../../../helpers/permissions'
import { resetSupplierData } from '../../../redux/actions/suppliers-actions'
import NavigatorDrawer from '../../../components/common/drawer/navigator-drawer'
import { PageContainer } from '../../../components/common/helpers'
import { ListItemRoute } from '../../../components/common/router-components'
import Category from './category'
import Type from './type'
import WebData from './web-data'
import { useRouteMatch } from 'react-router-dom'
import { usePermissionChecker } from '../../hooks'
import '../../styles/webInformation/webInformation.css'
import AOS from 'aos'
import { getUserRolId } from '../../../redux/selectors'
import DisabledLinks from './web-data/disabledLinks'


export default function WebInformation() {
  const { t } = useTranslation(['common', 'web-information'])
  const { path, url } = useRouteMatch()
  const dispatch = useDispatch()

  const userRolId = useSelector(getUserRolId)
  const permisosUser = userRolId == 1 ? true : false

  const { canDoThis } = usePermissionChecker()
  const canDefault = useMemo(
    () =>
      canDoThis(
        MARCA_BLANCA_CRUD_ADMIN,
        () => ({
          ishidden: true,
        }),
        () => ({
          ishidden: false,
        }),
      ),
    [canDoThis],
  )

  const childrenRoutes = permisosUser
    ? [
        {
          exact: true,
          path: [`${path}/`, `${path}/type`],
          component: Type,
        },
        {
          path: `${path}/category`,
          component: Category,
        },
      ]
    : [
        {
          exact: true,
          path: [`${path}/`, `${path}/webData`],
          component: WebData,
        },
        {
          exact: true,
          path: [`${path}/`, `${path}/disabledLinks`],
          component: DisabledLinks,
        },
      ]

  const header = (onOpen, btnRef) => (
    <Grid templateColumns={{ base: '5fr 1fr', md: '4fr 0fr' }} gap={[3, 3, 0]}>
      <Box backgroundColor='' py='16px' color='#103059'>
        <Heading style={{ fontSize: '1.8rem' }} mb={0}>
          {t('web-information:web-data')}
        </Heading>
      </Box>
      <Flex justify='flex-end'>
        <Button
          display={{ base: 'inherit', md: 'none' }}
          mb='10px'
          ref={btnRef}
          variant='outline'
          variantColor='blue'
          onClick={onOpen}
        >
          <Box as={GiHamburgerMenu} display='inline' />
        </Button>
      </Flex>
    </Grid>
  )
  useEffect(() => {
    dispatch(resetSupplierData())
    AOS.init({
      duration: 1000,
    })
    AOS.refresh()
  }, [dispatch])

  return (
    <PageContainer>
    
        {permisosUser && (
            <NavigatorDrawer header={header} routes={childrenRoutes} spacing='16px'>
          <List spacing={3}>
            <ListItemRoute to={`${url}/type`}>{t('web-information:type')}</ListItemRoute>
            <ListItemRoute to={`${url}/category`}>{t('web-information:category')}</ListItemRoute>
          </List>
          </NavigatorDrawer>
        )}
        {!permisosUser && (
           <NavigatorDrawer header={header} routes={childrenRoutes} spacing='16px'>
          <List spacing={3}>
            <ListItemRoute to={`${url}/webData`}>{t('web-information:web-data')}</ListItemRoute>
            <ListItemRoute to={`${url}/disabledLinks`}>{t('web-information:disabledLinks')}</ListItemRoute>
          </List>
                </NavigatorDrawer>
        )}

    </PageContainer>
  )
}
