import { Box, Button, Grid, PseudoBox, Checkbox, useToast, FormLabel, Textarea, Flex } from '@chakra-ui/core'
import React, { useState, useEffect, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { getCompanyTypes } from '../../../../api/web-information'
import { getCategoriesOfSuppliers } from '../../../../api/intranet'
import { useSelector } from 'react-redux'
import { getAuthToken, getUserCountryId } from '../../../../redux/selectors'
import SelectAsync from '../../../common/select-async'
import FormInput from '../../../forms/form-input'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import AlertDialogSimple from '../../../../components/common/alert-dialog-simple'
import ImageInput from '../../../common/image-input'
import { toastCustomizedError } from '../../../common/toast-default-config'
import { VALUE_TYPE_KEYS } from '../../../../helpers/commons'
import './styles.css'
import { ColorPicker } from 'antd'

const { COLOR, IMAGE, VALUE, LINK, PAQUETE } = VALUE_TYPE_KEYS
const inputTextEntries = [VALUE, LINK]

const defaultType = {
  componentToRender: null,
  typeValue: null,
}

export default function WebDataForm({ onSubmit, ...restProps }) {
  const { register, handleSubmit, formState, reset, setValue } = useForm()
  const { t } = useTranslation(['sections', 'web-information', 'forms', 'common'])
  const [categoryId, setCategoryId] = useState(undefined)
  const [headerChecked, setHeaderChecked] = useState(true)
  const [footerChecked, setFooterChecked] = useState(false)
  const [type, setType] = useState(defaultType)
  const [valueValue, setValueValue] = useState()
  const [urlLogo, setUrlLogo] = useState()
  const [categoryValue, setCategoryValue] = useState()
  const [color, setColor] = useState('#8dd3bb')
  const [textColor, setTextColor] = useState('#FFFFFF')
  const [characterCount, setCharacterCount] = useState(0)
  const maxCharacterCount = 500
  const [controlAlert, setControlAlert] = useState({
    datosC: null,
    show: false,
  })

  const authToken = useSelector(getAuthToken)
  const userCountryId = useSelector(getUserCountryId)
  const toast = useToast()

  const [categories, setCategories] = useState({
    data: [],
    isLoading: true,
  })

  const [types, setTypes] = useState({
    data: [],
    isLoading: true,
  })

  const getCompanyTypesCombo = async () => {
    setTypes(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      await getCompanyTypes(userCountryId, authToken).then(res => {
        const sortedData = res.data.sort((a, b) => {
          const descripcionA = a.descripcion.toLowerCase()
          const descripcionB = b.descripcion.toLowerCase()
          if (descripcionA < descripcionB) {
            return -1
          }
          if (descripcionA > descripcionB) {
            return 1
          }
          return 0
        })
        setTypes({
          data: sortedData ,
          isLoading: false,
        })
      })
    } catch (error) {
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  const getSuppliersCategories = async () => {
    setCategories(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const response = await getCategoriesOfSuppliers(userCountryId)
      setCategories({
        data: response.data,
        isLoading: false,
      })
    } catch (error) {
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  useEffect(() => {
    getSuppliersCategories()
    getCompanyTypesCombo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  ////Prop function OnSubmit

  const onSubmitt = useCallback(
    data => {
      // control
      if (!headerChecked && !footerChecked) {
        prepareCreate(data)
        return
      } else {
        const datos = {
          ...data,
          headerChecked,
          footerChecked,
          color,
          categoria: { id: categoryId },
        }
        if (type.componentToRender === PAQUETE) {
          datos.value = 'PAQUETE'
        }
        if (type.componentToRender === COLOR) {
          datos.value = JSON.stringify({ background: color, text: textColor })
        }
        onSubmit(datos)
      }
      resetForm()
    },
    [footerChecked, headerChecked, color, categoryId],
  )

  const createWebData = useCallback(() => {
    const datos = {
      ...controlAlert.datosC,
      headerChecked,
      footerChecked,
      categoria: { id: categoryId },
    }
    document.getElementById('web-data-form').reset()
    onCloseControlAlert()
    // setHeaderChecked(false);
    // setFooterChecked(false);
    onSubmit(datos)
  }, [footerChecked, headerChecked, categoryId])

  //// Hook and Functions control header and footer vacio

  function prepareCreate(data) {
    setControlAlert(state => ({
      ...state,
      show: true,
      datosC: data,
    }))
  }

  const resetForm = () => {
    document.getElementById('web-data-form').reset()
    setValueValue(undefined)
    setUrlLogo(undefined)
    setCategoryValue(undefined)
    setType(defaultType)
    setHeaderChecked(true)
    setFooterChecked(false)
    setCharacterCount(0)
    setColor('#8dd3bb')
    setTextColor('#FFFFFF')
  }

  const onCloseControlAlert = () => setControlAlert(state => ({ ...state, show: false }))

  return (
    <PseudoBox
      id='web-data-form'
      as='form'
      onSubmit={handleSubmit(d => {
        const category = categories.data.find(cat => cat.nombre === categoryValue)
        const newdata = {
          ...d,
          category,
        }
        if(type.componentToRender !== IMAGE){
          newdata.value = valueValue
        }
        return onSubmitt(newdata)
      })}
      {...restProps}
      style={{ backgroundColor: 'white', borderRadius: 10 }}
    >
      {/* Control checked header:true and footer:true */}
      <AlertDialogSimple
        isOpen={controlAlert.show}
        onClose={onCloseControlAlert}
        onAccept={createWebData}
        acceptLabel={t('common:accept')}
      >
        {t('web-information:checked-disabled')} <br />
      </AlertDialogSimple>

      <Grid
        templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
        gap={4}
        style={{ marginBottom: '1.5rem' }}
      >
        {/* Tipo */}
        <Box>
          <FormLabel htmlFor='type' isRequired>
            {t('forms:type')}
          </FormLabel>
          <SelectAsync
            isRequired
            name='type'
            value={type.typeValue || ''}
            onChange={e => {
              const typeDetail = types?.data?.find(type => type.id === parseInt(e.target.value))
              setCategoryId(Number(typeDetail.categoria.id))
              setType({
                componentToRender: typeDetail.categoria.tipoValor,
                name: typeDetail.categoria.nombre,
                typeValue: e.target.value,
                nameType: typeDetail.nombre,
                descriptionType: typeDetail.descripcion,
              })
            }}
            nativeProps={{ ref: register, placeholder: t('forms:selectOption') }}
            isLoading={types?.isLoading}
          >
            {types?.data?.map(type => (
              <option key={type.id} value={type.id}>
                {type.descripcion}
              </option>
            ))}
          </SelectAsync>
        </Box>
      </Grid>
      {type.componentToRender && type.componentToRender !== 'PAQUETE' && (
        <FormLabel htmlFor='value' isRequired mb={'15px'}>
          {t('forms:' + type.componentToRender.toLowerCase())}
        </FormLabel>
      )}
      <Grid
        templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
        gap={4}
        style={{ marginBottom: '2.5rem' }}
      >
        {type.componentToRender === COLOR && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              marginBottom: '10px',
            }}
          >
            <Box>
              <FormLabel>{t('common:background')}</FormLabel>
              <ColorPicker
                value={color}
                onChange={color => {
                  const backgroundColorHex = color.toHex()
                  setColor(`#${backgroundColorHex}`)
                }}
              />
            </Box>
            <Box>
              <FormLabel>{t('common:letter')}</FormLabel>
              <ColorPicker
                value={textColor}
                onChange={color => {
                  const textColorHex = color.toHex()
                  setTextColor(`#${textColorHex}`)
                }}
              />
            </Box>
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              backgroundColor={color}
              color={textColor}
              style={{ height: '100%', padding: '10px', marginLeft: '20px' }}
            >
              <p>
                {t('common:exampleMarcaBlanca1')}
                <br></br>
                {t('common:exampleMarcaBlanca2')}
              </p>
            </Box>
          </Box>
        )}
        {type.componentToRender === IMAGE && (
          <Grid gap={4}>
            <Box>
              {/* imagen URL */}
              <ImageInput
                isRequired
                name='value'
                setValue={setValue}
                inputProps={{
                  placeholder: `URL ${t('forms:image')}`,
                  ref: register,
                  type: 'text',
                }}
              />
            </Box>
            {type.name !== 'Header-Imagen' && (
              <Box>
                <FormLabel htmlFor='type'>{t('forms:url')}</FormLabel>
                <FormInput
                  name='url'
                  value={urlLogo}
                  onChange={e => setUrlLogo(e.target.value)}
                  inputProps={{
                    type: 'text',
                    ref: register,
                  }}
                />
              </Box>
            )}
          </Grid>
        )}
        {inputTextEntries.includes(type.componentToRender) && (
          <Box>
            {type.descriptionType !== 'Acerca de Nosotros' && type.nameType !== 'about-us-footer' ? (
              <FormInput
                isRequired
                name='value'
                value={valueValue}
                onChange={e => setValueValue(e.target.value)}
                inputProps={{
                  type: 'text',
                  ref: register,
                }}
              />
            ) : (
              <>
                <Textarea
                  isRequired
                  name='value'
                  value={valueValue}
                  height={180}
                  onPaste={e => {
                    e.preventDefault()
                    const pastedData = e.clipboardData.getData('text')
                    const trimmedData = pastedData.slice(0, 500)
                    setValueValue(trimmedData)
                    setCharacterCount(trimmedData.length)
                  }}
                  onChange={e => {
                    const newValue = e.target.value
                    if (newValue.length <= 500) {
                      setValueValue(newValue)
                      setCharacterCount(newValue.length)
                    }
                  }}
                  inputProps={{
                    type: 'text',
                    ref: register,
                  }}
                />
                <div className={characterCount === maxCharacterCount ? 'red-text' : ''} style={{ fontSize: '12px' }}>
                  Characters: {characterCount}/{maxCharacterCount}
                </div>
              </>
            )}
          </Box>
        )}
        {/* Checkboxs */}
      </Grid>
      <Grid
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-start',
        }}
      >
        <Box>
          {/* Es Footer */}
          <Box d='flex' justifyContent='center' alignSelf='center'>
            {t('web-information:active')}
            <Checkbox
              name='footer'
              ml='5'
              isChecked={footerChecked}
              onChange={e => setFooterChecked(e.target.checked)}
            />
          </Box>
        </Box>
      </Grid>
      {/* Guardar Button */}
      <Button mt={6} variantColor='blue' variant='outline' isLoading={formState.isSubmitting} type='submit'>
        {t('common:save')}
      </Button>
    </PseudoBox>
  )
}
