import React, { useEffect, useState } from 'react'
import { Panel } from '../../../common/helpers'
import ModalSimple from '../../../common/modal-simple'
import { useTranslation } from 'react-i18next'
import { useAsyncStateWithAction, useAsyncToastNotification, usePermission, usePermissionChecker } from '../../../hooks'
import moment from 'moment'
import Table from '../../../common/table'
import { useSelector } from 'react-redux'
import { getAllBannersState, getAuthToken, getBannersState, getUserInformation } from '../../../../redux/selectors'
import { useToast, Stack, Box, Text, Spinner, useDisclosure } from '@chakra-ui/core'
import { toastCustomizedSuccess, toastCustomizedError } from '../../../common/toast-default-config'
import { ButtonDelete, ButtonEdit } from '../../../forms/buttons/edit'
import BannerForm from './banner-form'
import AlertDialogSimple from '../../../../components/common/alert-dialog-simple'
import { BANNERS_CREATE } from '../../../../helpers/permissions'
import { createBanner, updateBannerById, deleteBanner } from '../../../../api/intranet/marca-blanca/banner'
import { useMemo } from 'react'
import { listAllBanners } from '../../../../redux/actions/banners-actions'
import { isSuccess } from '../../../../helpers/utils'
import Pagination from '../../../common/pagination'
import './styles.css'

export default function Banner() {
  const { addPermissions } = usePermission()
  const { t } = useTranslation(['common', 'forms'])
  const authToken = useSelector(getAuthToken)
  const toast = useToast()
  const { asyncNotificationWrapper } = useAsyncToastNotification()
  const { canDoThis } = usePermissionChecker()
  const user = useSelector(getUserInformation)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [deleteBannerOk, setDeleteBannerOk] = useState(false)

  //Modal vbles
  const modalDisclosure = useDisclosure()

  /* Habilita o no el btn para crear */
  const permisoCrud = useMemo(
    () =>
      canDoThis(
        BANNERS_CREATE,
        () => ({
          ishidden: false,
        }),
        () => ({
          ishidden: true,
        }),
      ),
    [canDoThis],
  )

  //Treaer Banners
  const dispatchedGetBanners = dispatch => dispatch(listAllBanners(user.aplicacion.id, page))
  const { error, isLoading, items, update } = useAsyncStateWithAction(dispatchedGetBanners, getAllBannersState)

  useEffect(() => {
    if (items) {
      setTotalPages(items?.totalPages)
      if (items?.totalPages < page) setPage(1)
    }
  }, [items])

  useEffect(() => {
    update()
  }, [page])

  // CREATE BANNERS
  async function submitBanner(formData) {
    if (formData?.categoria?.[0] !== false) {
      try {
        const response = await createBanner(formData, authToken)
        const status = isSuccess(response.status)

        if (status) {
          update()
          toast(toastCustomizedSuccess(t('forms:actionCompleted')))
        } else {
          toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
        }
      } catch (error) {
        toast(error)
      }
    } else {
      toast(toastCustomizedError(t('forms:categoryFormError')))
    }
  }

  function onSubmit(datos) {
    if (!datos.descripcion || datos.descripcion.length <= 250) {
      let formData = {
        empresaId: user.aplicacion.id,
        titulo: datos.titulo ?? null,
        descripcion: datos.descripcion ?? null,
        precio: datos.precio,
        descuento: datos.descuento,
        extra: datos.extra,
        imagen: datos.imagen ?? null,
        categoria: datos.categoria,
        orden: Number(datos.orden),
        url: datos.url,
        fechaInicio: datos.startDate === null ? '' : String(moment(datos.startDate).format()),
        fechaFin: datos.endDate === null ? '' : String(moment(datos.endDate).format()),
        esConsulta: datos.esConsulta,
        detalle: datos.detalle,
        itinerario: datos.itinerario,
        incluye: datos.incluye,
        hoteles: datos.hoteles,
        tarifas: datos.tarifas,
        salida: datos.salida,
        bannerImagenes: datos.bannerImagenes,
        proveedor: datos.proveedor,
      }
      submitBanner(formData)
    } else {
      toast(toastCustomizedError(t('forms:descriptionCannotBeMoreThan250Characters')))
    }
  }

  //EDIT FUNCTIONS
  const [idBanner, setIdBanner] = useState(0)
  const [dataEdit, setDataEdit] = useState(null)

  function onEditClick(id, data) {
    setIdBanner(id)
    setDataEdit(data)
    // modalDisclosure.onOpen()
  }

  const handleCancelInBanner = () => {
    setIdBanner(0)
    setDataEdit(null)
  }

  async function onSubmitUpdate(formdata) {
    if (!formdata.descripcion || formdata.descripcion.length <= 250) {
      const data = addPermissions(
        {
          bannerId: parseInt(idBanner),
          empresaId: user.aplicacion.id,
          categoria: formdata.categoria,
          descripcion: formdata.descripcion,
          descuento: formdata.descuento,
          extra: formdata.extra,
          fechaFin: formdata.endDate === undefined ? '' : String(moment(formdata.endDate).format()),
          fechaInicio: String(moment(formdata.startDate).format()),
          imagen: formdata.imagen,
          orden: Number(formdata.orden),
          precio: formdata.precio,
          titulo: formdata.titulo,
          url: formdata.url,
          esConsulta: formdata.esConsulta,
          detalle: formdata.detalle,
          itinerario: formdata.itinerario,
          incluye: formdata.incluye,
          hoteles: formdata.hoteles,
          tarifas: formdata.tarifas,
          salida: formdata.salida,
          bannerImagenes: formdata.bannerImagenes,
          proveedor: formdata.proveedor,
        },
        BANNERS_CREATE,
        )
        updateBanners(data, idBanner)
        setDataEdit(null)
        // modalDisclosure.onClose()
    } else {
      toast(toastCustomizedError(t('forms:descriptionCannotBeMoreThan250Characters')))
    }
  }

  async function updateBanners(data, idBanner) {
    try {
      const response = await updateBannerById(idBanner, data, authToken)
      if (isSuccess(response.status)) {
        update()
        toast(toastCustomizedSuccess(t('forms:actionCompleted')))
      } else {
        toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
      }
    } catch (error) {
      toast(error)
    }
  }

  //DELETE FUNCTIONS
  const [deleteAlert, setDeleteAlert] = useState({
    show: false,
    data: null,
  })

  //const [btnLoading, setBtnLoading] = useState(false)

  const onCloseDeleteAlert = () => {
    //setBtnLoading(false)
    setDeleteAlert(state => ({ ...state, show: false }))
  }

  function prepareDelete(data) {
    setDeleteAlert(state => ({
      ...state,
      data,
      show: true,
    }))
  }

  async function onDelete() {
    const data = addPermissions(
      {
        banner_id: parseInt(deleteAlert.data.id),
      },
      BANNERS_CREATE,
    )
    await asyncNotificationWrapper(async () => {
      const response = await deleteBanner(data.banner_id, authToken)
      update()
      setDeleteBannerOk(!deleteBannerOk)
      setIdBanner(0)
      setDataEdit(null)
      return response.status
    })
    onCloseDeleteAlert()
  }

  const truncateString = (str) => {
    return str?.length > 15 ? str.substring(0, 12) + "..." : str
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: `${t('forms:title')}`,
        accessor: 'titulo',
        Cell: ({ cell }) => (
          <p title={cell.row.values.titulo || ''}>
          {truncateString(cell.row.values.titulo)}
        </p>
        ),
      },
      {
        Header: `${t('forms:description')}`,
        accessor: 'descripcion',
        Cell: ({ cell }) => (
          <p title={cell.row.values.descripcion || ''}>
          {truncateString(cell.row.values.descripcion)}
        </p>
        ),
      },
      {
        Header: `Tag 1`,
        accessor: 'extra',
        Cell: ({ cell }) => (
          <p title={cell.row.values.extra || ''}>
          {truncateString(cell.row.values.extra)}
        </p>
        ),
      },
      {
        Header: `Tag 2`,
        accessor: 'precio',
        Cell: ({ cell }) => (
          <p title={cell.row.values.precio || ''}>
          {truncateString(cell.row.values.precio)}
        </p>
        ),
      },
      {
        Header: `Tag 3`,
        accessor: 'descuento',
        Cell: ({ cell }) => (
          <p title={cell.row.values.descuento || ''}>
          {truncateString(cell.row.values.descuento)}
        </p>
        ),
      },
      {
        Header: t('forms:order'),
        accessor: 'orden',
      },
      {
        Header: t('forms:endDate'),
        accessor: 'fechaFin',
        Cell: ({ cell }) => (
          <Stack spacing='5px' isInline>
            {!cell.row.values.fechaFin ? (
              <Text style={{ textAlign: 'center' }}>--</Text>
            ) : (
              <Text>{moment(cell.row.values.fechaFin).format('DD-MM-YYYY')}</Text>
            )}
          </Stack>
        ),
      },
      // {
      //   Header: t('forms:categoria'),
      //   accessor: row => {
      //     return row?.categoria?.nombre
      //   },
      // },
      {
        Header: t('forms:categoria'),
        accessor: 'categoria',
        Cell: ({ cell }) => (
          <p title={cell.row.values.categoria.nombre || ''}>
          {truncateString(cell.row.values.categoria.nombre)}
        </p>
        ),
      },
      {
        Header: t('forms:imagen'),
        accessor: 'imagen',
        maxWidth: 50,
        minWidth: 50,
        Cell: ({ cell: { value } }) => <img src={value} width={60} />,
      },
      {
        Header: t('common:actions'),
        accessor: null,
        Cell: ({ cell }) => (
          <Stack mx='1' spacing='4px' isInline d='flex' justifyContent='center'>
            <ButtonEdit
              mb={4}
              isDisabled={permisoCrud.ishidden === 'true' ? 'true' : ''}
              onClick={() => {
                return onEditClick(cell.row.values.id, cell.row.original)
              }}
            ></ButtonEdit>

            <ButtonDelete
              isDisabled={permisoCrud.ishidden === 'true' ? 'true' : ''}
              onClick={() => {
                return prepareDelete(cell.row.original)
              }}
            />
          </Stack>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  )

  return (
    <>
      {/* Alert delete */}
      <AlertDialogSimple
        isOpen={deleteAlert.show}
        onClose={onCloseDeleteAlert}
        onAccept={onDelete}
        acceptLabel={t('common:delete')}
      >
        {t('common:deleting')} <br />
        <b>{deleteAlert.data && `${deleteAlert.data.titulo} #${deleteAlert.data.id}`}</b>
      </AlertDialogSimple>

      {/* Modal de Edición */}
      {/* <ModalSimple
        isOpen={modalDisclosure.isOpen}
        onClose={modalDisclosure.onClose}
        title={`${t('common:edit')} ${t('banners:banners')}`}
        size='lg'
      >
        <BannerForm key='edit-form' formData={dataEdit} onSubmit={onSubmitUpdate} />
      </ModalSimple> */}

      {/* Formulario */}
      {dataEdit ?
        <Panel rounded='md'>
        <BannerForm formData={dataEdit} onSubmit={onSubmitUpdate} handleCancelInBanner={handleCancelInBanner} deleteBannerOk={deleteBannerOk}/>
      </Panel>
      :
      <Panel rounded='md'>
        <BannerForm onSubmit={onSubmit} />
      </Panel>
      }

      {/* Tabla */}
      <Box mt='8'>
        {!isLoading && !!items.data?.length && <Table columns={columns} data={items.data} />}
        {isLoading && (
          <Box px='5' py='10' textAlign='center'>
            <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
          </Box>
        )}
        {!isLoading && !error && !items.data && (
          <Text textAlign='center' color='gray.500' px='5' py='10'>
            {t('common:zeroItems')}
          </Text>
        )}
        {!isLoading && !items.data?.length && error && (
          <Text textAlign='center' color='gray.500' px='5' py='10'>
            {t('common:errorGettingItems')}
          </Text>
        )}
        {!isLoading && totalPages > 0 && <Pagination currentPage={page} setPage={setPage} totalPages={totalPages} />}
      </Box>
    </>
  )
}
