import { Box, Button, FormLabel, PseudoBox, Stack, Checkbox, useToast, Grid, Textarea } from '@chakra-ui/core'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getCompDetailById, getCompanyTypes, getLinkTypeById } from '../../../api/web-information'
import { VALUE_TYPE_KEYS, sketchPickerChangeColor } from '../../../helpers/commons'
import { getToken, getUserApplicationId } from '../../../redux/selectors'
import SelectAsync from '../../common/select-async'
import { useFormControls } from '../../hooks'
import FormInput from '../form-input'
import { SketchPicker } from 'react-color'
import AlertDialogSimple from '../../common/alert-dialog-simple'
import ImageInput from '../../common/image-input'
import { toastCustomizedError } from '../../common/toast-default-config'
import { ColorPicker } from 'antd'
import './styles.css'

export default function WebDataForm({ onSubmit, formData = false, ...restProps }) {
  const { t } = useTranslation(['forms'])
  const { isSubmitBlocked } = useFormControls()
  const token = useSelector(getToken)
  const { COLOR, IMAGE, VALUE, LINK } = VALUE_TYPE_KEYS
  const [characterCount, setCharacterCount] = useState(0)
  const maxCharacterCount = 500

  //Colores
  const [color, setColor] = useState('#8dd3bb')
  const [textColor, setTextColor] = useState('#FFFFFF')

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: formData,
  })
  const appId = useSelector(getUserApplicationId)

  ////Const Vaules inputs - OnChanges
  const [valueValue, setValueValue] = useState()
  const [urlLogo, setUrlLogo] = useState()
  const [activeValue, setActiveValue] = useState()
  const [colorPickerVisible, setColorPickerVisible] = useState(false)
  const [colorTextPickerVisible, setColorTextPickerVisible] = useState(false)

  async function loadValuesInputs(miCompDetail) {
    const tipoEnlaceObj = await getLinkTypeById(parseInt(miCompDetail.tipoEnlace.id), token)
    const color = miCompDetail.tipoEnlace.nombre === 'color-footer' ? JSON.parse(miCompDetail.valor) : ''
    setType({
      typeValue: tipoEnlaceObj.data.id,
      componentToRender: tipoEnlaceObj.data.categoria.tipoValor,
      nameType: tipoEnlaceObj.data.nombre,
      descriptionType: tipoEnlaceObj.data.descripcion,
    })
    setValueValue(miCompDetail.valor)
    setActiveValue(miCompDetail.esFooter)
    setUrlLogo(miCompDetail.url)
    setColor(color.background)
    setTextColor(color.text)
    setCharacterCount(miCompDetail.valor.length)
  }
  //// Load Company detail por id
  const [{ isLoading, companyDetail }, setCompDetailRequest] = useState({
    isLoading: true,
    companyDetail: {},
  })

  async function getCompanyDetail(compDetailId) {
    let miCompDetail = {}

    setCompDetailRequest(state => ({
      ...state,
      isLoading: true,
    }))

    await getCompDetailById(compDetailId, token)
      .then(res => {
        miCompDetail = res.data
      })
      // eslint-disable-next-line no-console
      .catch(error => console.log('ErrorCompDetById', error))

    setCompDetailRequest(state => ({
      ...state,
      isLoading: false,
      companyDetail: miCompDetail,
    }))
    loadValuesInputs(miCompDetail)
  }

  useEffect(() => {
    getCompanyDetail(restProps.idSection)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [categoryId, setCategoryId] = useState(undefined)

  ///On Guardar
  function onSubmitt(datos) {
    let formData = {
      empresaId: appId, //exHarcoded
      tipoEnlace: { id: parseInt(type.typeValue) },
      valor: datos.value,
      categoria: { id: categoryId },
      url: urlLogo,
    }
    // control
    if(type.componentToRender !== IMAGE){
      formData = {
        empresaId: appId, //exHarcoded
        tipoEnlace: { id: parseInt(type.typeValue) },
        valor: datos.value,
        categoria: { id: categoryId },
        esFooter: activeValue,
        url: urlLogo,
        valor: type.componentToRender !== COLOR ? valueValue : JSON.stringify({ background: color, text: textColor }),
      }
    }else{
      formData = {
        empresaId: appId, //exHarcoded
        tipoEnlace: { id: parseInt(type.typeValue) },
        valor: datos.value,
        categoria: { id: categoryId },
        esFooter: activeValue,
        url: urlLogo,
      }
    }
    onSubmit(formData)
  }

  //// Hook and Functions control header and footer vacio

  function createWebData() {
    let formData = {
      empresaId: appId, //exHarcoded
      tipoEnlace: { id: parseInt(controlAlert.datosC.type) },
      valor: controlAlert.datosC.value,
      categoria: { id: categoryId },
      esFooter: activeValue,
    }
    onSubmit(formData)
    onCloseControlAlert()
  }

  function prepareCreate(data) {
    setControlAlert(state => ({
      ...state,
      show: true,
      datosC: data,
    }))
  }

  const [controlAlert, setControlAlert] = useState({
    datosC: null,
    show: false,
  })

  const onCloseControlAlert = () => setControlAlert(state => ({ ...state, show: false }))

  const toast = useToast()

  const [types, setTypes] = useState({
    data: [],
    isLoading: true,
    error: false,
  })

  const getCompanyTypesCombo = async () => {
    setTypes(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      await getCompanyTypes(token).then(res => {
        const sortedData = res.data.sort((a, b) => {
          const descripcionA = a.descripcion.toLowerCase()
          const descripcionB = b.descripcion.toLowerCase()
          if (descripcionA < descripcionB) {
            return -1
          }
          if (descripcionA > descripcionB) {
            return 1
          }
          return 0
        })
        setTypes({
          data: sortedData,
          isLoading: false,
          error: false,
        })
      })
    } catch (error) {
      setTypes(state => ({
        ...state,
        error: true,
      }))
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  useEffect(() => {
    getCompanyTypesCombo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const defaultType = {
    componentToRender: null,
    typeValue: null,
  }

  ////Const Vaules inputs - OnChanges
  const [type, setType] = useState(defaultType)

  const inputTextEntries = [VALUE, LINK]

  const handlePicker = (e) => {
    if(colorPickerVisible && !e.target.offsetParent?.className?.startsWith('ant-')){
      setColorPickerVisible(false)
    }
    if(colorTextPickerVisible && !e.target.offsetParent?.className?.startsWith('ant-')){
      setColorTextPickerVisible(false)
    }
  }
  document.addEventListener("mousedown", handlePicker)

  return (
    <PseudoBox as='form' onSubmit={handleSubmit(d => onSubmitt(d))} {...restProps}>
      {/* Control checked header:true and footer:true */}
      <AlertDialogSimple
        isOpen={controlAlert.show}
        onClose={onCloseControlAlert}
        onAccept={createWebData}
        acceptLabel={t('common:accept')}
      >
        {t('web-information:checked-disabled')} <br />
      </AlertDialogSimple>
      {!isLoading && companyDetail && (
        <Stack>
          {/* Tipo */}
          <Box>
            <FormLabel htmlFor='type' isRequired>
              {t('forms:type')}
            </FormLabel>
            <SelectAsync
              isRequired
              isDisabled={true}
              placeholder={t('web-information:type')}
              name='type'
              value={type.typeValue}
              onChange={e => {
                const typeId = parseInt(e.target.value)
                const typeDetail = types.data.find(type => type.id === typeId)
                setValueValue(undefined)
                setCategoryId(parseInt(typeDetail.categoria.id))
                setType({
                  componentToRender: typeDetail.categoria.tipoValor,
                  typeValue: typeId,
                  nameType: typeDetail.nombre,
                  descriptionType: typeDetail.descripcion,
                })
              }}
              nativeProps={{ ref: register }}
              isLoading={types.isLoading}
            >
              {types.data.map(type => {
                return (
                  <option key={type.id} value={type.id}>
                    {type.descripcion}
                  </option>
                )
              })}
            </SelectAsync>
          </Box>
          {type.componentToRender && type.componentToRender !== 'PAQUETE' && (
            <FormLabel htmlFor='value' isRequired>
              {t('forms:' + type.componentToRender.toLowerCase())}
            </FormLabel>
          )}

          {type.componentToRender === COLOR && (
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                marginBottom: '10px',
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px',
                  marginBottom: '10px',
                }}
              >
                <Box>
                  <FormLabel>{t('common:background')}</FormLabel>
                  <ColorPicker
                    value={color}
                    open={colorPickerVisible}
                    onOpenChange={setColorPickerVisible}
                    onChange={color => {
                      const backgroundColorHex = color.toHex()
                      setColor(`#${backgroundColorHex}`)
                    }}
                  />
                </Box>
                <Box>
                  <FormLabel>{t('common:letter')}</FormLabel>
                  <ColorPicker
                    value={textColor}
                    open={colorTextPickerVisible}
                    onOpenChange={setColorTextPickerVisible}
                    onChange={color => {
                      const textColorHex = color.toHex()
                      setTextColor(`#${textColorHex}`)
                    }}
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                backgroundColor={color}
                color={textColor}
                style={{ height: '100%', padding: '10px' }}
              >
                <p>
                  {t('common:exampleMarcaBlanca1')}
                  <br></br>
                  {t('common:exampleMarcaBlanca2')}
                </p>
              </Box>
            </Box>
          )}
          {type.componentToRender === IMAGE && (
            <Grid gap={4}>
              {/* imagen URL */}
              <ImageInput
                isRequired
                name='value'
                value={valueValue}
                setValue={setValue}
                // onChange={e => setValueValue(e.target.value)}
                inputProps={{
                  placeholder: valueValue ? valueValue : `URL ${t('forms:image')}`,
                  ref: register,
                  type: 'text',
                }}
              />
              {(companyDetail.tipoEnlace.descripcion !== 'Imagen Header' ||
                companyDetail.tipoEnlace.nombre !== 'url-header-imagen') && (
                <Box>
                  <FormLabel htmlFor='type'>{t('forms:url')}</FormLabel>
                  <FormInput
                    name='url'
                    value={urlLogo}
                    onChange={e => setUrlLogo(e.target.value)}
                    inputProps={{
                      type: 'text',
                      ref: register,
                    }}
                  />
                </Box>
              )}
            </Grid>
          )}
          {inputTextEntries.includes(type.componentToRender) && (
            <>
              {/* Valor */}
              <Box>
                {type.descriptionType !== 'Acerca de Nosotros' && type.nameType !== 'about-us-footer' ? (
                  <FormInput
                    isRequired
                    name='value'
                    value={valueValue}
                    onChange={e => setValueValue(e.target.value)}
                    inputProps={{
                      type: 'text',
                      ref: register,
                    }}
                  />
                ) : (
                  <>
                    <Textarea
                      isRequired
                      name='value'
                      value={valueValue}
                      height={220}
                      onPaste={e => {
                        e.preventDefault()
                        const pastedData = e.clipboardData.getData('text')
                        const trimmedData = pastedData.slice(0, 500)
                        setValueValue(trimmedData)
                        setCharacterCount(trimmedData.length)
                      }}
                      onChange={e => {
                        const newValue = e.target.value
                        if (newValue.length <= 500) {
                          setValueValue(newValue)
                          setCharacterCount(newValue.length)
                        }
                      }}
                      inputProps={{
                        type: 'text',
                        ref: register,
                      }}
                    />
                    <div
                      className={characterCount === maxCharacterCount ? 'red-text' : ''}
                      style={{ fontSize: '12px' }}
                    >
                      Characters: {characterCount}/{maxCharacterCount}
                    </div>
                  </>
                )}
              </Box>
            </>
          )}

          {/* Checkboxs */}
          <Box d='flex' justifyContent='center'>
            {/* Es activo */}
            <Box d='flex' justifyContent='center' alignSelf='center' style={{ marginTop: '10px' }}>
              {t('web-information:active')}
              <Checkbox name='active' isChecked={activeValue} ml='5' onChange={e => setActiveValue(e.target.checked)} />
            </Box>
          </Box>

          <Button mt={6} variantColor='blue' variant='outline' type='submit' isLoading={isSubmitBlocked}>
            {t('forms:save')}
          </Button>
        </Stack>
      )}
    </PseudoBox>
  )
}