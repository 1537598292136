import React, { useMemo, useState, useEffect } from 'react'
import { useDisclosure, Stack, Box, Spinner, Text, useToast } from '@chakra-ui/core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  createCompanyDetail,
  deleteCompanyDetails,
  getLinkTypes,
  getAllCompanyDetails,
} from '../../../../api/web-information'
import WebDataForm from './web-data-form'
import { getToken, getAuthToken, getUserApplicationId } from '../../../../redux/selectors'
import { ButtonEdit } from '../../../forms/buttons/edit'
import { ButtonDelete } from '../../../forms/buttons/edit'
import Table from '../../../common/table'
import { Panel } from '../../../common/helpers'
import { toastSuccess, toastCustomizedError, toastCustomizedSuccess, toastCustomized } from '../../../common/toast-default-config'
import AlertDialogSimple from '../../../../components/common/alert-dialog-simple'
import CreateWebData from './create-web-data'
import ModalSimple from '../../../common/modal-simple'
import { isSuccess } from '../../../../helpers/utils'
import Pagination from '../../../common/pagination'

export default function WebData() {
  const { t } = useTranslation(['common', 'forms', 'web-information'])

  const authToken = useSelector(getAuthToken)
  const toast = useToast()
  const appId = useSelector(getUserApplicationId)
  const [idSection, setIdSection] = useState(0)
  const [sectionName, setSectionName] = useState('')
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)

  //Modal vbles
  const modalDisclosure = useDisclosure()
  const modalDisclosure2 = useDisclosure()

  const [{ isLoading, companyDetails }, setCompanyDetRequest] = useState({
    isLoading: false,
    companyDetails: [],
  })

  ///Create section
  async function createCompany(formData) {
    try {
      const response = await createCompanyDetail(formData, authToken)

      if (response && response?.data?.updated) {
        toast(
          toastCustomized(t('forms:existingType'), `${t('forms:updatedType')}. ID: ${response?.data?.data?.id}`, 'success', 6000),
        )
        getCompanyDetail()
      } else if (isSuccess(response.status)) {
        toast(toastCustomizedSuccess(t('forms:actionCompleted')))
        getCompanyDetail()
      } else {
        toastCustomizedError(t('forms:actionUncompleted'), t('forms:support'))
      }
    } catch (error) {
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  function onSubmit(datos) {
    let formData = {
      empresaId: appId,
      tipoEnlace: { id: parseInt(datos.type) },
      valor: datos.value,
      esHeader: datos.headerChecked,
      esFooter: datos.footerChecked,
      categoria: datos.categoria,
      url: datos.url,
    }
    createCompany(formData)
  }

  async function getCompanyDetail() {
    setCompanyDetRequest(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const res = await getAllCompanyDetails({ empresaId: appId, page })

      setTotalPages(res.data.totalPages)

      if (res.data.totalPages < page) setPage(1)
      setCompanyDetRequest(state => ({
        ...state,
        isLoading: false,
        companyDetails: res.data.data,
      }))
    } catch (error) {
      setCompanyDetRequest(state => ({
        ...state,
        isLoading: false,
      }))
      console.log('ErrorSesion', error)
    }
  }

  useEffect(() => {
    getCompanyDetail()
    getLinkTypes({ empresaId: appId })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  function onEditClick(idSectionEdit) {
    let idSection = idSectionEdit
    setIdSection(idSection)
    modalDisclosure.onOpen()
    // getCompanyDetail(page)
  }

  async function handleDelete() {
    try {
      const res = await deleteCompanyDetails(idSection, authToken)
      const status = isSuccess(res.status)

      toast(status ? toastSuccess : toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
      modalDisclosure2.onClose()
      getCompanyDetail()
    } catch (error) {
      console.log(error)
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: `${t('forms:type')}`,
        accessor: 'tipoEnlace',
        Cell: ({ cell }) => (
          <Stack spacing='5px' isInline>
            {cell.row.values.tipoEnlace.descripcion.length > 26 ? (
              <Text>{cell.row.values.tipoEnlace.descripcion.substring(0, 26)}...</Text>
            ) : (
              <Text>{cell.row.values.tipoEnlace.descripcion}</Text>
            )}
          </Stack>
        ),
      },
      {
        Header: t('forms:value'),
        accessor: 'valor',
        Cell: ({ cell }) => (
          <Stack spacing='5px' isInline>
            { cell.row.values.tipoEnlace?.categoria.tipoValor.toLowerCase() === 'color' ? (
              <>{t('common:color')}</>
      ) :
            cell.row.values.valor.toLowerCase() === 'paquete' ? (
              <></> // No mostrar nada si el valor es "paquete"
            ) : (
              cell.row.values.valor.length > 26 ? (
                <Text>{cell.row.values.valor.substring(0, 26)}...</Text>
              ) : (
                <Text>{cell.row.values.valor}</Text>
              )
            )}
          </Stack>
        ),
      },
      {
        Header: t('web-information:active'),
        accessor: 'esFooter',
        Cell: ({ cell }) => (
          <Stack spacing='5px' isInline>
            {cell.row.values.esFooter ? <Text>SI</Text> : <Text>NO</Text>}
          </Stack>
        ),
      },
      {
        Header: t('common:actions'),
        accessor: null,
        Cell: ({ cell }) => (
          <Stack spacing='5px' isInline>
            <ButtonEdit
              mb={4}
              onClick={() => {
                setSectionName(cell.row.values.tipoEnlace.descripcion)
                return onEditClick(cell.row.values.id)
              }}
            ></ButtonEdit>
            <ButtonDelete
              mb={4}
              onClick={() => {
                setIdSection(cell.row.values.id)
                setSectionName(cell.row.values.tipoEnlace.descripcion)
                return modalDisclosure2.onOpen()
              }}
            ></ButtonDelete>
          </Stack>
        ),
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ],
    [t],
  )

  async function reloadCompanyDetails() {
    try {
      await getCompanyDetail();
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Box>
      {/* Alert delete */}
      <AlertDialogSimple
        isOpen={modalDisclosure2.isOpen}
        onClose={modalDisclosure2.onClose}
        onAccept={handleDelete}
        title={`${t('common:delete')}: ${sectionName}`}
        size='lg'
      >
        {' '}
        <p>{`${t('common:deletingItem')}`}</p>
      </AlertDialogSimple>

      {/* Edit modal */}
      <ModalSimple
        isOpen={modalDisclosure.isOpen}
        onClose={modalDisclosure.onClose}
        title={`${t('common:edit')}: ${sectionName}`}
        size='lg'
      >
        <CreateWebData onFinish={reloadCompanyDetails} idSection={idSection} onClose={modalDisclosure.onClose}/>
      </ModalSimple>

      <Panel rounded='md' mb='1.5rem'>
        <WebDataForm onSubmit={onSubmit} />
      </Panel>
      {/* Tabla */}
      <Box mt='8'>
        {!isLoading && companyDetails?.length >= 1 ? <Table columns={columns} data={companyDetails} /> : null}

        {isLoading && <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />}
        {!isLoading && companyDetails?.length === 0 && (
          <Text textAlign='center' color='gray.500' px='5' py='10'>
            {t('common:zeroItems')}
          </Text>
        )}
        {!isLoading && !companyDetails && (
          <Text textAlign='center' color='gray.500' px='5' py='10'>
            {t('common:errorGettingItems')}
          </Text>
        )}
        {!isLoading && totalPages > 0 && <Pagination currentPage={page} setPage={setPage} totalPages={totalPages} />}
      </Box>
    </Box>
  )
}
